<template>
  <aRow :gutter="20">
    <aCol :span="6">
      <a-form-item>
        <label for class="filled">Valor total da Transferência</label>
        <a-input
          class="travel-input"
          placeholder="Insira..."
          v-currency
          @keyup="updateBankTransferValue"
          v-decorator="[
            `payment_methods_bank_transfer_total_value_${bankTransferId}`,
            {
              rules: [
                {
                  required: true,
                  message: 'Preencha todos números.',
                },
                {
                  validator: verifyValue,
                },
              ],
            },
          ]"
        >
          <a-icon slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol :span="6">
      <a-form-item class="travel-input-outer">
        <label for class="filled">Data de recebimento </label>
        <a-date-picker
          class="travel-input"
          placeholder="Selecione"
          format="DD/MM/YYYY"
          :showToday="false"
          :allowClear="true"
          v-mask="'##/##/####'"
          v-decorator="[
            `payment_methods_bank_transfer_receipt_date_${bankTransferId}`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon slot="prefix" type="field-svg" />
        </a-date-picker>
      </a-form-item>
    </aCol>

    <aCol :span="24">
      <a-form-item class="show-label">
        <a-radio-group
          name="radioGroup"
          v-decorator="[
            `payment_methods_bank_transfer_bank_${bankTransferId}`,
            {
              rules: [
                {
                  required: true,
                  message: 'Selecione um banco.',
                },
              ],
            },
          ]"
        >
          <a-radio
            v-for="(item, index) of banksList"
            :key="index"
            :value="item.account"
          >
            <strong style="font-weight: 600">
              {{ item.company_name }}
            </strong>
            <a-divider type="vertical" />
            <strong style="font-weight: 600">CNPJ/PIX:</strong>
            {{ item.cnpj }}
            <br />
            <div style="padding: 8px 0px 10px 28px">
              Agência:
              <strong style="font-weight: 600"> {{ item.agency }}</strong>
              <a-divider type="vertical" />
              Conta:
              <strong style="font-weight: 600"> {{ item.account }}</strong>
              <a-divider type="vertical" />
              {{ item.number }}
              -
              {{ item.name }}
            </div>
          </a-radio>
        </a-radio-group>
      </a-form-item>
    </aCol>
  </aRow>
</template>

<script>
import { parse } from "vue-currency-input";

export default {
  name: "BankTransferCollapse",
  props: {
    form: Object,
    bankTransferId: String,
    tempContract: Object,
  },
  data() {
    return {
      banksList: [],
    };
  },
  mounted() {
    this.$http
      .get(
        `/company-bank-accounts/list?page=1&per_page=200&status=Ativo&allow_payments=1`
      )
      .then(({ data }) => {
        this.banksList = data.data;
      });

    this.form.setFieldsValue({
      [`payment_methods_bank_transfer_total_value_${this.bankTransferId}`]:
        this.tempContract[
          `payment_methods_bank_transfer_total_value_${this.bankTransferId}`
        ],
      [`payment_methods_bank_transfer_receipt_date_${this.bankTransferId}`]:
        this.tempContract[
          `payment_methods_bank_transfer_receipt_date_${this.bankTransferId}`
        ],
      [`payment_methods_bank_transfer_bank_${this.bankTransferId}`]:
        this.tempContract[
          `payment_methods_bank_transfer_bank_${this.bankTransferId}`
        ],
    });

    this.$emit("updateBankTransferValue");
  },
  methods: {
    updateBankTransferValue() {
      this.$emit("updateBankTransferValue");
    },
    async verifyValue(rule, value, callback) {
      rule;
      let theValue = parse(value),
        totalValue = parseFloat(this.reservation_total_price);
      if (isNaN(theValue) || theValue == undefined) {
        theValue = 0;
      } else {
        if (parse(value) > totalValue) {
          callback(
            `O valor preenchido ultrapassa o total da reserva (R$ ${this.reservation_total_price}).`
          );
        } else {
          callback();
        }
      }
    },
  },
};
</script>